import { render, staticRenderFns } from "./ServiceCentersListItem.vue?vue&type=template&id=c0e6518a"
import script from "./ServiceCentersListItem.vue?vue&type=script&lang=js"
export * from "./ServiceCentersListItem.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiIcon: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod@2/components/ui/UiIcon.vue').default})
